import React from 'react'
import './LoginPage.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Snackbar,
Alert
} from "@mui/material";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from "@mui/icons-material";




function LoginPage() {

  const [state, setState] = React.useState({
    stage: 0, 
    phone: '',
    code: '', 
    loading: false, 
  }); 



  const auth = getAuth();
  const navigate = useNavigate(); 




function generateRecaptchaVerifier () {
 window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    'size': 'invisible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
     
    }
  }, auth);
}


function handleCodeSend (e) {
  e.preventDefault()
  setState((state) => ({
    ...state, 
    loading: true, 
  }))


  if (!window.recaptchaVerifier) {
      generateRecaptchaVerifier(); 
  }
  const appVerifer = window.recaptchaVerifier;
  signInWithPhoneNumber(auth, `+${state.phone}`, appVerifer)
  .then((res) => {
    window.confirmationResult  = res; 
    console.log(res)
    setState((state) => ({
      ...state, 
      stage: 1, 
      loading: false,
    }))
  }).catch((err) => {
    console.log(err)
    setState((state) => ({
      ...state, 
      errorOpen: true, 
      errorMessage: 'Oops... something went wrong refresh page and try again...', 
      successOpen: false, 
      successMessage: '', 
      loading: false, 
    }))
  })
}


function handleLogin () {
  if (!state.code.length === 6) {
    return
  }

  setState((state) => ({
    ...state, 
    loading: true
  }))

  const confirmationResult = window.confirmationResult; 

  confirmationResult.confirm(state.code).then((e) => {
    navigate('/add')
    setState((state) => ({
      ...state, 
      loading: false
    }))

  }).catch((err) => {
    console.log(err)
    setState((state) => ({
      ...state, 
      loading: false, 
      errorOpen: true, 
      errorMessage: 'Oops... looks like somethings gone wrong... please refresh and try again...'
    }))
  })
} 



        /* Handle Error and Success SnackBar */
        const handleClose = (event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setState((state) => ({
            ...state,
            successOpen: false,
            successMessage: "",
            errorMessage: "",
            errorOpen: false,
          }));
        };
      
        const [vertical] = React.useState("bottom");
        const [horizontal] = React.useState("right");



  return (
    
    <div className='LoginPage'>
           <Snackbar
          open={state.successOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>

     


<div id='recaptcha-container'></div>
      <div className='content'>
{state.loading && <div className='Loginloading'><CircularProgress /></div>}
        {state.stage === 0 ?

<>

<div className='PhoneTitle'>
  Because passwords are lame,
  please login using your 
  mobile number. 
</div>


<PhoneInput
country={'au'}
inputStyle={{width:'100%'}}
value={state.phone}
onChange={(e) => {setState((state) => ({...state, phone: e}))}}
/>

<div className='buttonSection'>
<Button onClick={((e) => {handleCodeSend(e)})}>
Send Code
</Button>

</div>
</>

:
     
<>

<div style={{width:'100%'}}>
  <IconButton onClick={((e) => {setState((state) => ({...state, stage: 0}))})}>
  <ArrowBackIos />
</IconButton>
</div>


<div className='PhoneTitle'>
  Now to make sure you're you
  please enter the code we just 
sent
</div>



<TextField value={state.code} onChange={((e) => {setState((state) => ({...state, code: e.target.value}))})} label='Code' type={'number'} fullWidth  inputProps={{min: 0, style: { textAlign: 'center' }}} />


<div className='buttonSection'>
<Button onClick={((e) => {handleLogin(e)})}>
 Login
</Button>

</div>
</>   
      
      }



      </div>
    </div>
  )
}

export default LoginPage