import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import {
  getFirestore,
} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDbULfqCJh5MGr5vQGg-oqUrLtby-KlMbo",
  authDomain: "things2see-8b71d.firebaseapp.com",
  projectId: "things2see-8b71d",
  storageBucket: "things2see-8b71d.appspot.com",
  messagingSenderId: "1016161745694",
  appId: "1:1016161745694:web:a08901beda77d5b7e6a70f",
  measurementId: "G-0L7CKR3CVD"
};
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);




const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return({success: true, message: 'successfully logged user in, you will be redirected shortly...'})
  } catch (err) {
    return({success: false, message: 'Failed to log user in please check credentials and try again...', err})
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
   const res = await createUserWithEmailAndPassword(auth, email, password)
  const user = res.user;
  return({success: true, message: 'Successfully created user...', user})
  } catch (err) {
    return({success: false, message: 'Failed to create user...'})
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return({success: true, message: 'Successfully sent password reset link to provided email address...'})
  } catch (err) {
    return({success: false, message: 'Failed to send password reset link please check email address...'})
  }
};

const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  storage,
  functions,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
