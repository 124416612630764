import { ArrowBackIos, LocationSearching } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Slider,
  TextField,
  Snackbar,
Alert
} from "@mui/material";
import React from "react";
import "./Add.css";
import GoogleMapReact from "google-map-react";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { geohashForLocation } from "geofire-common";
import { getFunctions, httpsCallable } from "firebase/functions";

function Add() {
  const [state, setState] = React.useState({
    title: "",
    desc: "",
    days: 30,
    marker: {
      lat: 0,
      lng: 0,
    },
    coords: { lat: 59.955413, lng: 30.337844 },
    loading: true, 
    errorOpen: '',
errorMessage: '',
submitLoading: false,
  });


  const auth = useAuth(); 
  const navigate = useNavigate(); 

  React.useEffect( () => {

    if (!auth.currentUser) {
        navigate('/login')

    }

    console.log('fetching')


        async function handleDetails(coords) {
        console.log(coords)
      await setState((state) => ({
        ...state,
        coords,
        marker: coords
      }))



      setTimeout(() => {
               setState((state) => ({
          ...state,
          loading: false,
        }));
      }, 500);
    
 
    }
    
    if (navigator.geolocation) {

        function success(position) {
            console.log('dealing')
            const coords = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            handleDetails(coords);
          }
          
          function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
          }

          const options = {
            enableHighAccuracy: false,
            timeout: 6000,
            maximumAge: Infinity
          };


        console.log('location')

        navigator.geolocation.getCurrentPosition(success, error, options);         
       
     

    

    } else {
        setState((state) => ({
            ...state, 
           loading: false,
        }))
    }



  }, [auth.currentUser, navigate]); 



  const AnyReactComponent = ({ text }) =><div className="iconParent"><LocationSearching className="iconChild" color="primary" /></div> ; 


  function handleUpload () {
    // validate inputs 
    setState((state) => ({
      ...state, 
      loading: true, 
      submitLoading: true, 
    }))
    if (
      state.title.length < 4 ||
      state.desc.length < 10 
    ) {
      setState((state) => ({
        ...state, 
        errorOpen: true, 
        errorMessage: 'Oops... Looks like we need a few more details...', 
        loading: false, 
        submitLoading: false,
      }))
    } else {
      handleActualUpload()
    }



  }


  function handleActualUpload () {


    const hash = geohashForLocation([state.marker.lat, state.marker.lng]); 


    const collectionPath = collection(db, 'events'); 
    addDoc(collectionPath, {
      duration: state.days, 
      created_at: serverTimestamp(), 
      description: state.desc, 
      title: state.title, 
      owner: auth.currentUser.uid,
      coords: state.marker, 
      geohash: hash, 
    }).then((res) => {
      // navigate back to map page


      if (window.confirm("🚀 Would you like to boost this thing for only $3?")) {

        console.log(auth.currentUser.uid)
        const functions = getFunctions(); 
        const fetchUrl = httpsCallable(functions, 'eventBoost'); 
        fetchUrl({
             eventId: res.id,  
        }).then((res) => {
        window.open(res.data.url)
        setState((state) => ({
          ...state, 
          loading: false, 
          submitLoading: false, 
        }))

        }).catch((err) => {
          console.log(err)
          setState((state) => ({
            ...state, 
            loading: false, 
            submitLoading: false, 
          }))
        })
        console.log('boost')
      } else {
        setState((state) => ({
          ...state, 
          loading: false, 
          submitLoading: false, 
        }))
  
        setTimeout(() => {
          navigate('/')
          
        }, 300);
    }
     
    }).catch((err) => {
      console.log(err)
      setState((state) => ({
        ...state, 
        errorOpen: true, 
        errorMessage: 'Oops... an error occured please refresh and try again...', 
        loading: false, 
        submitLoading: false,
      }))
    })
  }


          /* Handle Error and Success SnackBar */
          const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setState((state) => ({
              ...state,
              successOpen: false,
              successMessage: "",
              errorMessage: "",
              errorOpen: false,
            }));
          };
        
          const [vertical] = React.useState("bottom");
          const [horizontal] = React.useState("right");


  return (
    <div className="Add">
      <div className="content">
      <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>



        <>
          <IconButton onClick={((e) => {navigate('/')})} style={{ marginBottom: "10px" }}>
            <ArrowBackIos />
          </IconButton>
        </>
   <div className="PageTitle">
            Post a new interesting thing...
        </div>
        <div className="fullwidth">
          <TextField
            label="Title"
            placeholder="Interesting Thing...."
            fullWidth
            disabled={state.submitLoading}
            value={state.title}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                title: e.target.value
              }))
            })}
          />
        </div>
        <div className="fullwidth">
          <TextField
            label="Description"
            multiline
            disabled={state.submitLoading}
            value={state.desc}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                desc: e.target.value
              }))
            })}
            minRows={4}
            maxRows={10}
            placeholder="Wow look at this very interesting thing...."
            fullWidth
          />
        </div>

        <div className="mapSection">

            {state.loading ? 
            <div className="Maploading">
                <CircularProgress />
                </div>
            :
            


          <GoogleMapReact
          disabled={state.submitLoading}
            bootstrapURLKeys={{
              key: "AIzaSyAeOr6acqCbl31sXl0Mh1evnfrrZdu0824",
            }}
            defaultCenter={state.coords}
            defaultZoom={15}
            options={{ disableDefaultUI: true }}
            onClick={(e) => {
              console.log(e.lat);

              const {lat, lng} = e; 

              setState((state) => ({
                ...state, 
                marker: {lat, lng}
              }))
            }}
          >
            <AnyReactComponent
              lat={state.marker.lat}
              lng={state.marker.lng}
              text="My Marker"
            />
          </GoogleMapReact>
}


        </div>

        <div className="sliderSection">
          <div className="helder">1</div>
          <Slider
            defaultValue={3}
            min={1}
            aria-label="Default"
            max={101}
            valueLabelDisplay="off"
            value={state.days}
            disabled={state.submitLoading}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                days: e.target.value,
              }));
            }}
          />
          <div className="helder">∞</div>
        </div>

        <div className="daysCounter">
          <div>
            {state.days === 101 ? `No End Date...` : `${state.days} Days`}
          </div>
        </div>

        <div className="buttonSection">
          <Button
          disabled={state.submitLoading}
            onClick={(e) => {
              handleUpload();
            }}
            style={{
              backgroundColor: "rgb(30, 114, 210)",
              color: "white",
              fontWeight: "bold",
              width: "70%",
            }}
          >
          {state.submitLoading ? <CircularProgress size={25} style={{color:'white'}}/> : 'Post!'}  
          </Button>
        </div>
      </div>{" "}
    </div>
  );
}

export default Add;
