import React from "react";
import GoogleMapReact from "google-map-react";
import {
  CircularProgress,
  Fab,
  IconButton,
  Dialog,
  Button,
} from "@mui/material";
import "./Map.css";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LocationOn from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
} from "firebase/firestore";
import { db } from "../../firebase";
import { distanceBetween, geohashQueryBounds } from "geofire-common";
import boosted from './boostedLogo.svg'
import { Replay } from "@mui/icons-material";

function Map() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [state, setState] = React.useState({
    coords: { lat: -36.36017540066043, lng: 144.70368605121018 },
    loading: true,
    markers: [],
    activeMarker: {},
    center: {}
  });

  React.useEffect(() => {
    if (navigator.userAgent.includes("Instagram")) {
      window.open('things2see.app')
    }
  }, [])
  

  function handleFetchRefresh () {
    setState((state) => ({
      ...state, 
      loading: true
    }))
  handleFetch([state.center.lat, state.center.lng]).then((setState((state) => ({
    ...state, 
    loading: false, 
  }))))
  }

  async function handleFetch(defaultLatLng) {
    // Find events within 50km of User
    const center = defaultLatLng;
    const radiusInM = 50 * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];

    for (const b of bounds) {
      const q = query(
        collection(db, "events"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
      promises.push(getDocs(q));
    }

    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const { lat, lng } = doc.data().coords;
            console.log(lat, lng);

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
              matchingDocs.push(doc);
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        // Process the matching documents
        setState((state) => ({
          ...state,
          markers: matchingDocs.map((doc) => ({ ...doc.data(), id: doc.id })),
        }));
      });
  }

  React.useEffect(() => {
    console.log("fetching");

    async function handleDetails(coords) {
      //      console.log(coords)
      await setState((state) => ({
        ...state,
        coords,
      }));

      setTimeout(() => {
        setState((state) => ({
          ...state,
          loading: false,
        }));
        handleFetch([coords.lat, coords.lng]);
      }, 500);
    }

    if (navigator.geolocation) {
      function success(position) {
        console.log("dealing");
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        handleDetails(coords);
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      const options = {
        enableHighAccuracy: false,
        timeout: 6000,
        maximumAge: Infinity,
      };

      console.log("location");

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  }, []);

  function handleReturnData(date, total) {
    if (date && total !== 101) {
      console.log(date);
      const jsServerDate = new Date(date.toDate());
      jsServerDate.setDate(jsServerDate.getDate() + total);
      const currentDate = new Date();

      const diff = jsServerDate.getTime() - currentDate.getTime();
      let TotalDays = Math.ceil(diff / (1000 * 3600 * 24));

      console.log(TotalDays);

      return `${TotalDays} Days Left...`;
    } else {
      return null;
    }
  }

  function deletePost() {
    const docRef = doc(db, "events", state.activeMarker.id);
    deleteDoc(docRef)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function handleEmailTo () {

    const link = `mailto:admin@things2see.app?subject=Reported Thing&body=Hi,I found this event and have found an issue it's id is ${state.activeMarker.id}`;
    window.open(link)
  }



  function DialogWindow() {
    return (
      <Dialog
        open={state.open}
        fullWidth
        maxWidth="xs"
        onClose={(e) => {
          setState((state) => ({ ...state, open: false }));
        }}
      >
        <div className="dialogContent">
          <IconButton
            className="iconButton"
            onClick={(e) => {
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${state.activeMarker.coords.lat}%2C${state.activeMarker.coords.lng}`
              );
            }}
          >
            <NearMeIcon color="primary" />
          </IconButton>

          <div className="title">{state.activeMarker.title}</div>

          <div className="description">{state.activeMarker.description}</div>

          <div className="days">
            {handleReturnData(
              state.activeMarker.created_at,
              state.activeMarker.duration
            )}
          </div>

          <div className="reportDiv">
            <Button
              size="small"
              onClick={(e) => {
                auth.currentUser &&
                state.activeMarker.owner === auth.currentUser.uid
                  ? deletePost()
                  : handleEmailTo();
              }}
            >
              {auth.currentUser &&
              state.activeMarker.owner === auth.currentUser.uid
                ? "Delete Post"
                : "Report Issue"}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  function handleClick(data) {
    console.log(data.data.boosted)
    setState((state) => ({
      ...state,
      open: true,
      activeMarker: data.data,
    }));
  }

  const AnyReactComponent = (data) => (
    <div className="markerIconParent">


{data.data.boosted ? 

<Button  onClick={(e) => {
  handleClick(data);
}} style={{borderRadius:'100px'}} className="iconIconButton">
<img  alt='' className="iconButtonImg" src={boosted}/>
</Button>




:

<IconButton
onClick={(e) => {
  handleClick(data);
}}
className="icon"
>
<LocationOn color="primary" fontSize="large" /> 

</IconButton>


}

    </div>
  );



  function handleChange (e) {
    console.log(e.center)

    setState((state) => ({
      ...state, 
      center: e.center
    }))
  }



  return (
    <>
      <DialogWindow />

      {state.loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="mapParent">
          <GoogleMapReact
          onChange={handleChange}
            style={{ width: 100, height: 100 }}
            bootstrapURLKeys={{
              key: "AIzaSyAeOr6acqCbl31sXl0Mh1evnfrrZdu0824",
            }}
            defaultCenter={state.coords}
            defaultZoom={15}
            options={{ disableDefaultUI: true }}
          >
            {state.markers.map((marker) => (
              <AnyReactComponent
                lat={marker.coords.lat}
                lng={marker.coords.lng}
                data={marker}
              />
            ))}
          </GoogleMapReact>

          <IconButton
            className="refreshButton"
          size="small"

            onClick={(e) => {
             handleFetchRefresh()
            }}
          >
            <Replay size='small'/>
          </IconButton>


          <Fab
            className="fabIcon"
            color="primary"
            onClick={(e) => {
              navigate("/add");
            }}
          >
            <AddLocationIcon />
          </Fab>
        </div>
      )}
    </>
  );
}

export default Map;
