import "./App.css";
import Map from "./Components/MapPage/Map";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Add from "./Components/AddPage/Add";
import LoginPage from "./Components/AddPage/LoginPage/LoginPage";
import {AuthProvider} from "./Contexts/AuthContext.js";


function App() {
  return (
    <div >
      <Router>
      <AuthProvider> 
          <Routes>
            <Route path="/" element={<Map />} />
            <Route path="/add" element={<Add />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
          </AuthProvider> 
      </Router>
    </div>
  );
}

export default App;
